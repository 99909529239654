import { useEffect } from "react";
import ScrollAnim from "rc-scroll-anim";
import QueueAnim from "rc-queue-anim";
import useIsMobile from "../hooks/useIsMobile";

const defiImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/emp-defi.svg";
const journeysImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/emp-journeys.svg";
const connectImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/emp-connect.svg";
const bridgeImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/emp-bridge.svg";
const featureImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/emp-feature.svg";
const controlImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/emp-control.svg";

export const loadImage = (url, callback) => {
  var img = new Image();
  img.onload = function () {
    img.onload = null;
    callback(img);
  };
  img.src = url;
};
const Empower = () => {
  const { isMobile } = useIsMobile();
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack;
  const MyQueueAnim = isMobile ? "div" : QueueAnim;
  useEffect(() => {
    ScrollAnim.scrollScreen.unMount();
  }, []);

  const list = [
    {
      title: "Build data-driven DeFi",
      desc: "Dynamically adjust key parameters like trading fee percentages or liquidity incentives for your protocol to improve capital efficiency, trading returns, and protocol revenue.",
      imgSrc: defiImg,
    },
    {
      title: "Create unique user journeys",
      desc: "Optimize acquisition, retention, and engagement of different user segments with unique onboarding and live-ops features.",
      imgSrc: journeysImg,
    },
  ];
  const list2 = [
    {
      title: "Establish financial connections",
      desc: "Attest on-chain connections between users, or users and protocols, to confirm campaign eligibility, run referral programs, or build social recovery wallets.",
      imgSrc: connectImg,
    },
    {
      title: "Build secure bridges",
      desc: "Trustlessly query a message from one chain and verify the message on another without relying on external validation models.",
      imgSrc: bridgeImg,
    },
  ];
  const list3 = [
    {
      title: "Create privacy-preserving features",
      desc: "Protect your campaign against Sybil attacks without requiring identity proofs to be disclosed.",
      imgSrc: featureImg,
    },
    {
      title: "Implement trustless risk control",
      desc: "Incorporate risk management models directly in smart contracts that are responsive to truthful data computation.",
      imgSrc: controlImg,
    },
  ];
  useEffect(() => {
    list?.forEach((item) => {
      loadImage(item.imgSrc, () => {});
    });
    list2?.forEach((item) => {
      loadImage(item.imgSrc, () => {});
    });
    list3?.forEach((item) => {
      loadImage(item.imgSrc, () => {});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="empower">
      <div className="empower_body">
        <ScrollOverPack
          key={"empower_level1"}
          id={"empower_level1"}
          playScale={0.4}
          always={false}
        >
          <MyQueueAnim type="bottom" key={"empower_title"} duration={2000}>
            <div className="empower_title" key="empower_title2">
              <div>EMPOWERING</div>
              <div>
                DATA-RICH
                {!isMobile ? <span> USE CASES</span> : <div>USE CASES</div>}
              </div>
            </div>
          </MyQueueAnim>
        </ScrollOverPack>
        <div className="empower_list">
          <ScrollOverPack
            key={"empower_level21"}
            id={"empower_level21"}
            playScale={0.42}
            always={false}
          >
            <div className="empower_level">
              {list.map((item, index) => {
                return (
                  <div
                    className="empower_item"
                    key={"empower_level11" + index.toString()}
                  >
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level111" + index.toString()}
                      duration={2000}
                    >
                      <div
                        className="empower_item_img"
                        key={"empower_item_img" + index.toString()}
                      >
                        <img src={item.imgSrc} alt="" />
                      </div>
                    </MyQueueAnim>
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level112" + index.toString()}
                      duration={2000}
                      delay={300}
                    >
                      <div
                        className="empower_item_text_title"
                        key={"empower_item_text_title" + index.toString()}
                      >
                        {item.title}
                      </div>
                    </MyQueueAnim>
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level113" + index.toString()}
                      duration={2000}
                      delay={500}
                    >
                      <div
                        className="empower_item_text_desc"
                        key={"empower_item_text_desc" + index.toString()}
                      >
                        {item.desc}
                      </div>
                    </MyQueueAnim>
                  </div>
                );
              })}
            </div>
          </ScrollOverPack>
          <ScrollOverPack
            key={"empower_level22"}
            id={"empower_level22"}
            playScale={0.425}
            always={false}
          >
            <div className="empower_level">
              {list2.map((item, index) => {
                return (
                  <div
                    className="empower_item"
                    key={"empower_level221" + index.toString()}
                  >
                    <MyQueueAnim type="bottom" key={index} duration={2000}>
                      <div
                        className="empower_item_img"
                        key={"empower_item_img2" + index.toString()}
                      >
                        <img src={item.imgSrc} alt="" />
                      </div>
                    </MyQueueAnim>
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level331" + index.toString()}
                      duration={2000}
                      delay={300}
                    >
                      <div
                        className="empower_item_text_title"
                        key={"empower_item_text_title2" + index.toString()}
                      >
                        {item.title}
                      </div>
                    </MyQueueAnim>
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level1541" + index.toString()}
                      duration={2000}
                      delay={500}
                    >
                      <div
                        className="empower_item_text_desc"
                        key={"empower_item_text_desc2" + index.toString()}
                      >
                        {item.desc}
                      </div>
                    </MyQueueAnim>
                  </div>
                );
              })}
            </div>
          </ScrollOverPack>
          <ScrollOverPack
            key={"empower_level3"}
            id={"empower_level3"}
            playScale={0.43}
            always={false}
          >
            <div className="empower_level">
              {list3.map((item, index) => {
                return (
                  <div
                    className="empower_item"
                    key={"empower_level1561" + index.toString()}
                  >
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level1781" + index.toString()}
                      duration={2000}
                    >
                      <div
                        className="empower_item_img"
                        key={"empower_item_img3" + index.toString()}
                      >
                        <img src={item.imgSrc} alt="" />
                      </div>
                    </MyQueueAnim>
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level1771" + index.toString()}
                      duration={2000}
                      delay={300}
                    >
                      <div
                        className="empower_item_text_title"
                        key={"empower_item_text_title3" + index.toString()}
                      >
                        {item.title}
                      </div>
                    </MyQueueAnim>
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level1871" + index.toString()}
                      duration={2000}
                      delay={500}
                    >
                      <div
                        className="empower_item_text_desc"
                        key={"empower_item_text_desc3" + index.toString()}
                      >
                        {item.desc}
                      </div>
                    </MyQueueAnim>
                  </div>
                );
              })}
            </div>
          </ScrollOverPack>
        </div>
      </div>
    </div>
  );
};
export default Empower;
