import { useState, useEffect, useMemo } from "react";
import throttle from "lodash/throttle";

/* eslint-disable */

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(
    document.documentElement.clientWidth <= 720
  );
  const [windowWidth, setWindowWidth] = useState(
    document.documentElement.clientWidth
  );
  const onResize = useMemo(
    () =>
      throttle(() => {
        setIsMobile(document.documentElement.clientWidth <= 960);
        setWindowWidth(document.documentElement.clientWidth);
      }, 400),
    []
  );
  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  return { isMobile, windowWidth };
}

export default useIsMobile;
