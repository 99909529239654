import ScrollAnim from "rc-scroll-anim";
import QueueAnim from "rc-queue-anim";
import { openDoc } from "./Header";
import { openTalk } from "./FirstPageContent";
import useIsMobile from "../hooks/useIsMobile";
const helpYouImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/helpYou.svg";

const HelpYou = () => {
  const { isMobile } = useIsMobile();
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack;
  const MyQueueAnim = isMobile ? "div" : QueueAnim;
  const str = "Ready to build".toUpperCase();
  const str2 = "with trustless".toUpperCase();
  const str3 = "computation".toUpperCase();
  const str4 = "Ready to build with".toUpperCase();
  const str5 = "trustless computation".toUpperCase();
  return (
    <div className="helpYou">
      <div className="helpYou_body">
        <ScrollOverPack
          key={"helpYou"}
          id={"introduce"}
          playScale={0.35}
          appear={!isMobile}
          always={!isMobile}
        >
          <MyQueueAnim type="bottom" duration={3000}>
            <div className="helpYou_title" key="helpYou_title">
              {isMobile ? (
                <div>
                  <div>{str}</div>
                  <div>{str2}</div>
                  {str3}?
                </div>
              ) : (
                <div>
                  <div>{str4}</div>
                  <div>{str5}?</div>
                </div>
              )}
            </div>
          </MyQueueAnim>
          <MyQueueAnim type="bottom" duration={3000} delay={300}>
            <div className="helpYou_img" key={"helpYou_img"}>
              <img src={helpYouImg} alt="" />
            </div>
          </MyQueueAnim>
          <MyQueueAnim type="bottom" duration={3000} delay={600}>
            <div className="helpYou_buttons" key={"helpYou_buttons"}>
              <div
                className="helpYou_button1"
                onClick={() => {
                  openDoc();
                }}
              >
                SDK docs
              </div>
              <div
                className="helpYou_button2"
                onClick={() => {
                  openTalk();
                }}
              >
                Talk to a builder
              </div>
            </div>
          </MyQueueAnim>
        </ScrollOverPack>
      </div>
    </div>
  );
};
export default HelpYou;
