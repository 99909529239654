import { useState } from "react";
import useIsMobile from "../hooks/useIsMobile";
import { openTalk } from "./FirstPageContent";
const closeImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/close.svg";
const menuImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/menu.svg";
const logoImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/logo.svg";

export const openWhitePaper = () => {
  window.open(
    " https://get.celer.app/brevis/BrevisWhitePaper_09271015.pdf",
    "_blank"
  );
};
export const openBlog = () => {
  window.open("https://blog.brevis.network/", "_blank");
};
export const openDoc = () => {
  window.open("https://docs.brevis.network/", "_blank");
};
export const openTelegram = () => {
  window.open("https://t.me/brevisnetwork", "_blank");
};
const Header = () => {
  const { isMobile } = useIsMobile();
  const [showMenu, setShowMenu] = useState(false);
  const [docIn, setDocIn] = useState(false);
  // const [whitepaperIn, setWhitepaperIn] = useState(false);
  const [blogIn, setBlogIn] = useState(false);
  const [tegIn, setTegIn] = useState(false);
  return (
    <div className="header">
      {isMobile && (
        <div className="mobileHeader">
          <img
            alt=""
            className="mobileLogo"
            src={logoImg}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
          {!showMenu ? (
            <img
              alt=""
              className="menuIcon"
              src={menuImg}
              onClick={() => {
                setShowMenu(true);
              }}
            />
          ) : (
            <img
              alt=""
              className="menuIcon"
              src={closeImg}
              onClick={() => {
                setShowMenu(false);
              }}
            />
          )}
        </div>
      )}
      {(showMenu || !isMobile) && (
        <div className="headerCenter">
          <div className="headerLeft">
            {!isMobile && (
              <img
                alt=""
                className="logo"
                src="https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/logo.svg"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              />
            )}
            <div
              className="headerBtn"
              onClick={() => {
                openDoc();
              }}
              onMouseEnter={() => {
                setDocIn(true);
              }}
              onMouseLeave={() => {
                setDocIn(false);
              }}
            >
              {isMobile ? (
                "Docs"
              ) : (
                <div
                  className={docIn ? "textMotion_cubeIn" : "textMotion_cubeOut"}
                  style={{ width: 48 }}
                >
                  <div className="textMotion_cube_item_front">Docs</div>
                  <div className="textMotion_cube_item_bottom">Docs</div>
                </div>
              )}
            </div>
            {/* <div
              className="headerBtn"
              onClick={() => {
                openWhitePaper();
              }}
              onMouseEnter={() => {
                setWhitepaperIn(true);
              }}
              onMouseLeave={() => {
                setWhitepaperIn(false);
              }}
            >
              {isMobile ? (
                "Whitepaper"
              ) : (
                <div
                  className={
                    whitepaperIn ? "textMotion_cubeIn" : "textMotion_cubeOut"
                  }
                  style={{ width: 111 }}
                >
                  <div className="textMotion_cube_item_front">Whitepaper</div>
                  <div className="textMotion_cube_item_bottom">Whitepaper</div>
                </div>
              )}
            </div> */}
            <div
              className="headerBtn"
              onClick={() => {
                openBlog();
              }}
              onMouseEnter={() => {
                setBlogIn(true);
              }}
              onMouseLeave={() => {
                setBlogIn(false);
              }}
            >
              {isMobile ? (
                "Blog"
              ) : (
                <div
                  className={
                    blogIn ? "textMotion_cubeIn" : "textMotion_cubeOut"
                  }
                  style={{ width: 48 }}
                >
                  <div className="textMotion_cube_item_front">Blog</div>
                  <div className="textMotion_cube_item_bottom">Blog</div>
                </div>
              )}
            </div>
            <div
              className="headerBtn"
              onClick={() => {
                openTelegram();
              }}
              onMouseEnter={() => {
                setTegIn(true);
              }}
              onMouseLeave={() => {
                setTegIn(false);
              }}
            >
              {isMobile ? (
                "Telegram"
              ) : (
                <div
                  className={tegIn ? "textMotion_cubeIn" : "textMotion_cubeOut"}
                  style={{ width: 88 }}
                >
                  <div className="textMotion_cube_item_front">Telegram</div>
                  <div className="textMotion_cube_item_bottom">Telegram</div>
                </div>
              )}
            </div>
          </div>
          <div className="headerRight">
            <div
              className="headerButton"
              onClick={() => {
                openTalk();
              }}
            >
              Talk to a builder
            </div>
            {/* <div
              className="headerButton2"
              onClick={() => {
                openDemo();
              }}
            >
              Try demos
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};
export default Header;
