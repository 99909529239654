import "animate.css";
import "./App.css";
import FirstPage from "./pages/FirstPage";
import Step from "./pages/Step";
import Introduce from "./pages/Introduce";
import Empower from "./pages/Empower";
import HelpYou from "./pages/HelpYou";
import Footer from "./pages/Footer";

function App() {
  return (
    <div className="App">
      <FirstPage />
      <Step />
      <Introduce />
      <Empower />
      <HelpYou />
      <Footer />
    </div>
  );
}

export default App;
