import { useEffect } from "react";
import ScrollAnim from "rc-scroll-anim";
import QueueAnim from "rc-queue-anim";
import { loadImage } from "./Empower";
import useIsMobile from "../hooks/useIsMobile";
const performantImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/performant.svg";
const versatileImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/versatile.svg";
const omniChainImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/omniChain.svg";
const performant_sImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/performant_s.svg";
const versatile_sImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/versatile_s.svg";
const omniChain_sImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/omniChain_s.svg";

const Introduce = () => {
  const { isMobile } = useIsMobile();
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack;
  const MyQueueAnim = isMobile ? "div" : QueueAnim;
  const list = [
    {
      title: "PERFORMANT",
      subTitle: "at scale",
      desc: "Powered by advanced ZK cryptographic algorithms and large-scale parallel computing, Brevis can process complex computations on thousands of transactions in minutes.",
      imgSrc: isMobile ? performant_sImg : performantImg,
    },
    {
      title: "VERSATILE",
      subTitle: (
        <div>
          SDKs programmable
          <div>in your familiar languages</div>
        </div>
      ),
      desc: "Easily access historic states, transactions, events of any smart contract or account, and customize your own computation logic in popular languages such as Go.",
      imgSrc: isMobile ? versatile_sImg : versatileImg,
    },
    {
      title: "OMNI-CHAIN",
      subTitle: "data access and verification",
      desc: "Offer a unified user experience with fast and trustless access to omnichain data and arbitrary cross-chain computation.",
      imgSrc: isMobile ? omniChain_sImg : omniChainImg,
    },
  ];
  useEffect(() => {
    list?.forEach((item) => {
      loadImage(item.imgSrc, () => {});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="introduce">
      <div className="introduce_body">
        <ScrollOverPack
          key={"introduce2"}
          id={"introduce"}
          playScale={0.32}
          // appear={!isMobile}
          // always={!isMobile}
        >
          <div className="introduce_list">
            {list.map((item, index) => {
              return (
                <MyQueueAnim
                  key={index}
                  type="bottom"
                  duration={3000}
                  delay={isMobile ? 0 : 400 + index * 100}
                >
                  <div
                    className="introduce_item"
                    key={"introduce_item" + index.toString()}
                  >
                    <div className="introduce_item_text_title">
                      {item.title}
                    </div>
                    <div className="introduce_item_text_subTitle">
                      {item.subTitle}
                    </div>
                    <div className="introduce_item_img">
                      <img src={item.imgSrc} alt="" />
                    </div>
                    <div className="introduce_item_text_desc">{item.desc}</div>
                  </div>
                </MyQueueAnim>
              );
            })}
          </div>
        </ScrollOverPack>
      </div>
    </div>
  );
};
export default Introduce;
