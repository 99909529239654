import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "data",
  initialState: {
    showModal: false,
  },
  reducers: {
    setShowModal: (state, { payload }) => {
      state.showModal = payload;
    },
  },
});

export const { setShowModal } = counterSlice.actions;

export default counterSlice.reducer;
